.img {
  height: 40px;
}

.bg {
  height: 100vh;
  background: url("./img/goku.jpeg");
  background-size: 100% auto;
}

.img1 {
  height: 30px;
  background: url("./img/chatgpt1.png");
  background-size: auto 200%;
  background-position: center;
  background-repeat: no-repeat;
}